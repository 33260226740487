<template>
    <div class="accordion" id="accordionExample">
        <div class="card card-radius" >
            <div class="accordion-header"  id="headingOne">
                <div class="card-header header-white">
                    <div>
                        <span class="btn btn-link btn-block text-right" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">


                            <span class="fw500 d-inline-block text-regular-sm text-muted " v-if="lang == 'es'" >Editar</span>
                            <span class="fw500 d-inline-block text-regular-sm text-muted" v-else >Edit</span>


                            <span class="caret float-center d-inline-block">
                                <svg width="2em" height="1.5em" viewBox="0 0 16 16" class="bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"></path>
                                </svg>
                            </span>


                        </span>
                        <div class="row">
                            <div class="col-6">
                                <ul class="no-list mb-0">
                                    <li class="fw600 mb-1 text-subtitle-md">
                                        <span class="fw500 d-block text-regular-sm text-muted" v-if="lang == 'es'" >Retiro</span>
                                        <span class="fw500 d-block text-regular-sm text-muted" v-else >Pickup</span>
                                        {{ pickup_text }}
                                    </li>
                                    <li>
                                        <i class="fa fa-calendar mr-2 text-secondcolor-dark text-subtitle-md"></i> {{ pickup_date }}
                                    </li>
                                    <li>
                                        <i class="fa fa-clock mr-2 text-secondcolor-dark text-subtitle-md"></i> {{ pickup_hour }} hrs
                                    </li>
                                </ul>
                            </div>
                            <div class="col-6">
                                <ul class="no-list mb-0">
                                    <li class="fw600 mb-1 text-subtitle-md">
                                        <span class="fw500 d-block text-regular-sm text-muted" v-if="lang == 'es'" >Devolución</span>
                                        <span class="fw500 d-block text-regular-sm text-muted" v-else >Drop Off</span>
                                        {{ dropoff_text }}
                                    </li>
                                    <li>
                                        <i class="fa fa-calendar mr-2 text-secondcolor-dark text-subtitle-md"></i> {{ dropoff_date }}
                                    </li>
                                    <li>
                                        <i class="fa fa-clock mr-2 text-secondcolor-dark text-subtitle-md"></i> {{ dropoff_hour }} hrs
                                    </li>
                                </ul>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div id="collapseOne" class="collapse " aria-labelledby="headingOne" data-parent="#accordionExample">
                    <div class="card-body">
                        <form id="booking-form-mobile" action="" name="booking-form-mobile">
                            <!-- inputs hidden -->
                            <input type="hidden" id="language-mobile" name="language">
                            <input type="hidden" id="urlpost-mobile" name="urlpost">
                            <input type="hidden" id="provider_id-mobile" name="provider_id">

                            <!-- Pickup & dropoff options -->
                            <div class="row mx-0">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label v-if="lang == 'es'" for="pickup">Retiro</label>
                                        <label v-else for="pickup">Pick Up</label>
                                        <div class="input-group">
                                            <select class="form-control" id="pickup-mobile" name="pickup">
                                                <slot></slot>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mx-0">
                                <div class="col-sm-12">
                                    <div class="custom-control custom-switch mb-2">
                                        <input type="checkbox" name="dropoff_checked" class="custom-control-input custom-control-input-alt" id="return-somewhere-else-mobile" data-active="0">
                                        <label class="custom-control-label custom-control-label-alt fw600" v-if="lang == 'es'" for="return-somewhere-else-mobile">Devolver en otro lugar</label>
                                        <label class="custom-control-label custom-control-label-alt fw600" v-else for="return-somewhere-else-mobile">Return somewhere else</label>
                                    </div>
                                </div>
                            </div>

                            <div class="row dropoff-selector mx-0" style="display:none;">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <select class="form-control" id="dropoff-mobile" name="dropoff">
                                                <slot></slot>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- End pickup & dropoff options -->

                            <!-- Calendar options -->
                            <div class="row mx-0">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label v-if="lang == 'es'" for="pickup-date">Fecha de Retiro</label>
                                        <label v-else for="pickup-date">Pick Up Date</label>

                                        <input type="date" id="pickup-date-mobile" name="pickup_date" class="datetimepicker datepicker form-control" readonly/>
                                    </div>
                                </div>

                                <div class="col-6">
                                    <div class="form-group">
                                        <label v-if="lang == 'es'" for="pickup-time">Hora</label>
                                        <label v-else for="pickup-time">Pick Up Hour</label>

                                        <input type="time" id="pickup-time-mobile" name="pickup_hour" class="datetimepicker timepicker form-control" readonly/>
                                    </div>
                                </div>
                            </div>

                            <div class="row mx-0">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label v-if="lang == 'es'" for="dropoff-date">Fecha de Devolución</label>
                                        <label v-else for="dropoff-date">Drop Off Date</label>

                                        <input type="date" id="dropoff-date-mobile" name="dropoff_date" class="datetimepicker datepicker form-control" readonly/>
                                    </div>
                                </div>

                                <div class="col-6">
                                    <div class="form-group">
                                        <label v-if="lang == 'es'" for="dropoff-time">Hora</label>
                                        <label v-else for="dropoff-time">Drop Off Hour</label>

                                        <input type="time" id="dropoff-time-mobile" name="dropoff_hour" class="datetimepicker timepicker form-control" readonly/>
                                    </div>
                                </div>
                            </div>

                            <div class="row mx-0">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <select class="form-control" id="type-car-mobile" name="type_car" placeholder="TIPO DE AUTO">
                                                <slot></slot>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                
                            <!-- End calendar options -->

                            <div class="row mx-0 my-2">
                                <div class="col-12 text-center">
                                    <div class="input-btn w-100">
                                        <input id="booking-buttom-mobile" class="btn btn-search mb-0 border-0 h-100" type="submit" :value="reserve_button_text"/>
                                        <label for="booking-buttom-mobile" class="icon"><i class="fa fa-search" style="line-height: unset;"></i></label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import Select2 from 'select2'
    import flatpickr from 'flatpickr'

    export default {
        props: ['settings'],
        data() {
            return {
                lang: "",
                show_tab: true,
                is_homepage: true,
                reserve_button_text: "Buscar",
                pickup_text : this.settings.pickup_placeholder,
                pickup_date : this.settings.pickup_date,
                pickup_hour : this.settings.pickup_hour,
                dropoff_text : this.settings.dropoff_placeholder,
                dropoff_date : this.settings.dropoff_date,
                dropoff_hour : this.settings.dropoff_hour
            }
        },
        mounted() {

            var site_id         = this.settings.site_id
            var domain_url      = this.settings.domain_url
            var location_id     = this.settings.location_id
            var provider_id     = this.settings.provider_id
            var lang            = this.settings.lang
            var dropoff_id_user = this.settings.location_dropoff_id
            var pickup_date     = this.settings.pickup_date
            var dropoff_date    = this.settings.dropoff_date
            var pickup_hour     = this.settings.pickup_hour
            var dropoff_hour    = this.settings.dropoff_hour
            var tipo_auto       = this.settings.tipo_auto
            var is_homepage     = this.settings.homepage
            var pickup_text     = this.settings.pickup_placeholder
            var dropoff_text    = this.settings.dropoff_placeholder
            var params          = {lang}

            this.lang           = lang
            this.is_homepage    = is_homepage
            var blockUI_message = 'Cargando...'
            if (lang == 'en') {
                this.reserve_button_text = 'Find a Car'
                blockUI_message          = 'Please wait..'
            }
            var blockUI_conf = {
                message: '<div class="loader-container-block-ui"> <div class="loader-block-ui"></div> </div>',
                css: {
                    border: 'none',
                    padding: '5px',
                    backgroundColor: 'transparent',
                    //backgroundColor: '#000',
                    '-webkit-border-radius': '10px',
                    '-moz-border-radius': '10px',
                    //opacity: .5,
                    color: '#fff',
                    width: '60%',
                    left:'20%'
                }
            }
            $('#language-mobile').val(lang)
            $('#provider_id-mobile').val(provider_id)

            /** Selects pickup, drop off and type car */
            $('#pickup-mobile').select2({
                theme: 'bootstrap4',
                placeholder: this.settings.pickup_placeholder
            })

            /** Clicked when is empty */
            $('#select2-pickup-mobile-container').on('click', function() {
                //load after first render when clicked the select
                $('#pickup-mobile').select2('close');
                loadInit(true);
                getTypeCar(provider_id, location_id, false)
            })
            // On change event function
            $('#pickup-mobile').on('select2:select', function (event) {
                // Call new date from new location pickup selected
                location_id = $(this).val()
                getDropoff(location_id)
                getTypeCar(event.params.data.provider, event.params.data.id, false)
                $('#urlpost-mobile').val(event.params.data.url)
                $('#provider_id-mobile').val(event.params.data.provider)
            })

            //Interruptor functionality
            $('#return-somewhere-else-mobile').on('change', function(e) {
                var active = parseInt($(this).data('active'))
                var checked = $(this).prop('checked')

                if (!active) {
                    //(0) it was activated
                    $('.dropoff-selector').slideDown()
                    $(this).data('active', 1)

                    let dropoff_id = $('#dropoff-mobile').val()
                    // If has data not make request
                    if (dropoff_id == null) { getDropoff(location_id, false) }
                } else {
                    //(1) it was desactived
                    $('.dropoff-selector').slideUp()
                    $(this).data('active', 0)
                    $('#dropoff-mobile').val(location_id).trigger('change')
                }
            })

            $('#dropoff-mobile').select2({
                theme: 'bootstrap4',
                placeholder: this.settings.pickup_placeholder
            })

            /** Clicked when is empty */
            $('#select2-dropoff-mobile-container').on('click', function() {
                // Load data first time
                $('#dropoff-mobile').select2('close');
                getDropoff(location_id, true)
            });

            $('#type-car-mobile').select2({
                theme: 'bootstrap4',
                placeholder: this.settings.vehicle_placeholder,
                dropdownPosition: 'below'
            });

            /** Clicked when is empty */
            $('#select2-type-car-mobile-container').on('click', function() {
                // Load data first time
                $('#type-car-mobile').select2('close')
                getTypeCar(provider_id, location_id, true)
            })

            // Calendars flatpickr
            var minDate = (typeof pickup_date == 'undefined') ? moment().add(1, 'days').format('YYYY-MM-DD') : moment(pickup_date).format('YYYY-MM-DD')
            $('#pickup-date-mobile').flatpickr({
                disableMobile: true,
                dateFormat: "Y-m-d",
                defaultDate: minDate,
                minDate: moment().add(1, 'days').format('YYYY-MM-DD'),
                onChange: function(selectedDates, dateStr, instance) {
                    var nextDay = moment(dateStr).add(1, 'days').format('YYYY-MM-DD')
                    var drop_calendar = $('#dropoff-date-mobile').flatpickr({
                        disableMobile: true,
                        dateFormat: "Y-m-d",
                        defaultDate: nextDay,
                        minDate: nextDay
                    })

                    drop_calendar.open()
                }
            })

            var time = (typeof pickup_hour == 'undefined') ? moment("2099-12-30 11:00") : moment("2099-12-30 " + pickup_hour)
            $('#pickup-time-mobile').flatpickr({
                disableMobile: true,
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true,
                defaultDate: moment(time).format('HH:mm')
            })

            var minDateD = (typeof dropoff_date == 'undefined') ? moment(minDate).add('1', 'days').format('YYYY-MM-DD') : moment(dropoff_date).format('YYYY-MM-DD')
            $('#dropoff-date-mobile').flatpickr({
                disableMobile: true,
                dateFormat: "Y-m-d",
                defaultDate: minDateD,
                minDate: moment(minDate).add(1, 'days').format('YYYY-MM-DD')
            })

            var timeD = (typeof dropoff_hour == 'undefined') ? moment("2099-12-30 11:00") : moment("2099-12-30 " + dropoff_hour)
            $('#dropoff-time-mobile').flatpickr({
                disableMobile: true,
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true,
                defaultDate: moment(timeD).format('HH:mm')
            });

            // Avoid multiple click
            $(this).prop('disabled', false)

            //submit form
            $('#booking-form-mobile').on('submit', function (event) {
                event.preventDefault()
                event.stopPropagation()

                $('#booking-buttom-mobile').prop('disabled', true)

                let url      = $('#urlpost-mobile').val()
                let lang     = $('#language-mobile').val()
                // let redirect = (lang === 'en') ? url.substring(3, url.length) : url
                var params   = $(this).serialize()

                if (is_homepage) { $.blockUI(blockUI_conf) };
                

                ajaxRequest('/booking', params, 'POST', {"X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")}).then(response => {
                    $.ajaxRequest('/component/vehicles').then(HTML => {
                        window.location = decodeURIComponent(url)
                    }).catch(err => {
                        Swal.fire('Ups!', 'Something went wrong! ' +  err.responseJSON.message, 'error')
                        console.log(err)
                    }).finally(() => { 
                        $.unblockUI();
                        $('#booking-buttom-mobile').prop('disabled', false)
                     })
                }).finally(() => {
                    $.unblockUI();
                    $('#booking-buttom-mobile').prop('disabled', false)
                })
            })

            $('#edit-form-mobile').on('submit', function(event) {
                event.preventDefault()
                event.stopPropagation()

                var reservation_code  = $('#reservation_code').val()
                var reservation_email = $('#reservation_email').val()
                var url = '';
                if (lang == 'es') {
                    url = `/editar/reservacion/${reservation_code}/${reservation_email}`
                } else {
                    url = `/en/edit/reservation/${reservation_code}/${reservation_email}`
                }

                window.location = url;
            })

            /** In case we want to load everything from
                the begining you can use this */
            $(document).ready(function(event) {
                $.unblockUI();
                loadInit()

                $('#return-somewhere-else-mobile').prop('checked', false)
                $('#return-somewhere-else-mobile').data('active', 0)

                //getDropoff(location_id)
                if (tipo_auto != 0 && typeof tipo_auto != 'undefined') {
                    getTypeCar(provider_id, location_id, false)
                }

                if (typeof dropoff_id_user != 'undefined' && location_id != dropoff_id_user && is_homepage == false) {
                    $('#return-somewhere-else-mobile').click()
                }

                $('#booking-buttom-mobile').prop('disabled', false)
            })

            function loadInit (openDropdown = false) {
                //load data from booking first render
                $.ajax({
                    url: "/pickups",
                    dataType: 'json',
                    data: params,
                    async: true,
                }).done(function(response) {

                    if (response.error) {
                        Swal.fire('Ups!', 'Something went wrong! ' + response.message, 'error')
                        return true;
                    }       

                    var result = $.map(response.data, function (item, index) {
                        if (lang == 'en') {
                            var url = item.booking_url.replace(/\/en/, '');
                        }else{
                            var url = item.booking_url.replace(/\//, '/es/');
                        }
                        if (item.id == location_id) {$('#urlpost').val(url)}
                        return {id: item.id, text: item.name, provider:item.provider_id, code: item.code, url: url}
                    })

                    var provider_id = 0;
                    // Move to first element
                    $(result).each(function(index, item) {
                        if (item.id == location_id) {
                            result.unshift(item)
                            result.splice(index + 1 , 1)
                            provider_id = item.provider
                        }
                    })

                    $('#pickup-mobile').empty()
                    $('#pickup-mobile').select2({
                        theme: 'bootstrap4',
                        data: result,
                        selectOnClose: true
                    })

                    $('#pickup-mobile').val(location_id)
                    if (openDropdown) { $('#pickup-mobile').select2('open') }
                }).fail(function(xhr, status, error) {
                    Swal.fire('Ups!', 'Something went wrong!: ' + xhr.responseJSON.error, 'error');
                })
            }
            //Request to fill dropoff selector
            const getDropoff = (location_id, openDropdown = false) => {

                params.site_id     = this.settings.site_id
                params.domine      = this.settings.domain_url
                params.lang        = lang
                params.location_id = location_id

                ajaxRequest('/dropoffs', params).then(response => {
;
                    if (response.error) {
                        Swal.fire('Ups!', 'Something went wrong! ' + response.message, 'error')
                        return true
                    }

                    //processing data
                    response.data.filter((location) => {
                        if (location.id == location_id) {
                            var result = [];
                            $.each(location.dropoffs, function(index, dropoff) {

                                result.push({id: dropoff.id, text: dropoff.name});
                            });

                            $('#dropoff-mobile').empty()
                            $('#dropoff-mobile').select2({
                                theme: 'bootstrap4',
                                data: result
                            });

                            $('#dropoff-mobile').parent().find(".select2-container").css('width', '100')

                            var active = parseInt($('#return-somewhere-else-mobile').data('active'));
                            if (!active) {
                                $('#dropoff-mobile').val(location_id).trigger('change')
                            } else {
                                // Is active
                                if (typeof dropoff_id_user != 'undefined') { $('#dropoff-mobile').val(dropoff_id_user).trigger('change') }
                                if (openDropdown) { $('#dropoff-mobile').select2('open') }
                            }
                        }

                    })
                })
            }

            //get type cars by provider
            const getTypeCar = (provider_id, location_id, openDropdown = true) => {

                let lang        = params.lang
                let type        = 'type'
                let code_search = provider_id

                ajaxRequest('/location/url', {lang, type, location_id, code_search}).then(response => {
                    
                    if (response.error) {
                        Swal.fire('Error', response.message, 'error');
                        return true;
                    }
                    
                    var result_type = $.map(response.data, function (item) {
                        return {id: item.id, text: item.description}
                    })
                    
                    result_type.unshift({id: 0, text: this.settings.vehicle_placeholder})

                    $('#type-car-mobile').empty()
                    $('#type-car-mobile').select2({
                        theme: 'bootstrap4',
                        data: result_type,
                        dropdownPosition: 'below'
                    })

                    if (tipo_auto != 0 && typeof tipo_auto != 'undefined') {
                        $('#type-car-mobile').val(tipo_auto).trigger('change');
                    }

                    if (openDropdown) { $('#type-car-mobile').select2('open') }
                })
            }

            //call ajax method
            const ajaxRequest = (url, params = [], method = "GET", headers = [], async = true) => {
                return new Promise((resolve, reject) => {
                    $.ajax({
                        url : url,
                        method : method,
                        headers: headers,
                        dataType: 'json',
                        data : params,
                        async : true
                    }).done(resolve).fail(function(error) {
                        Swal.fire(
                            error.status + ' - Error',
                            error.responseJSON.error,
                            'error'
                        );
                        console.log(error)
                    });
                })
            }
        }
    }

    $('#pickup-mobile').parent().find(".select2-container").css('width', '100')
    $('#dropoff-mobile').parent().find(".select2-container").css('width', '100')
</script>