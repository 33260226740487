<template>

    <!-- Booking -->
    <div class="booking-frm" v-bind:class="[ is_homepage ? 'shadow-custom' : 'booking-frm-p-rates border-custom booking-rates' ]">
        <div v-bind:class="[is_homepage ? 'header-tabs-booking' : 'd-none d-md-block header-tabs-booking']" >
            <ul class="nav nav-tabs border-bottom-0 mb-2 d-flex justify-content-between" id="myTab" role="tablist">
                <li class="nav-item" v-bind:class="[ show_tab ? 'one-tab' : '' ]" role="presentation">
                    <a v-if="lang == 'es'" class="color-black-hover nav-link active no-decoration text-regular-lg" id="tab-1" data-toggle="tab"
                       href="#tab-booking" role="tab" aria-selected="true">Reservar auto</a>
                    <a v-else class="color-black-hover nav-link active no-decoration text-regular-lg" id="tab-1" data-toggle="tab"
                       href="#tab-booking" role="tab" aria-selected="true">Book a Car</a>
                </li>

                <li class="nav-item" role="presentation">
                    <a v-if="lang == 'es'" class="color-black-hover nav-link no-decoration" href="https://crbooksystem.com/" target="_blank">Mi Reserva</a>
                    <a v-else class="color-black-hover nav-link no-decoration" href="https://crbooksystem.com/en" target="_blank">My Booking</a>
                </li>
            </ul>
        </div>

        <div class="tab-content" id="myTabContent">
            
            <div class="tab-pane fade active show" id="tab-booking" role="tabpanel">
                <form id="booking-form" action="" name="booking-form">
                    <!-- inputs hidden -->
                    <input type="hidden" id="language" name="language">
                    <input type="hidden" id="urlpost" name="urlpost">
                    <input type="hidden" id="provider_id" name="provider_id">

                    <!-- Pickup & dropoff options -->
                    <div class="row mx-0">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label v-if="lang == 'es'" for="pickup">Retiro</label>
                                <label v-else for="pickup">Pick Up</label>
                                <div class="input-group">
                                    <select class="form-control" id="pickup" name="pickup">
                                        <slot></slot>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mx-0">
                        <div class="col-sm-12">
                            <div class="custom-control custom-switch mb-2">
                                <input type="checkbox" name="dropoff_checked" class="custom-control-input custom-control-input-alt" id="return-somewhere-else" data-active="0">
                                <label class="custom-control-label custom-control-label-alt fw600" v-if="lang == 'es'" for="return-somewhere-else">Devolver en otro lugar</label>
                                <label class="custom-control-label custom-control-label-alt fw600" v-else for="return-somewhere-else">Return somewhere else</label>
                            </div>
                        </div>
                    </div>

                    <div class="row dropoff-selector mx-0" style="display:none;">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <select class="form-control" id="dropoff" name="dropoff">
                                        <slot></slot>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End pickup & dropoff options -->

                    <!-- Calendar options -->
                    <div class="row mx-0">
                        <div class="col-sm-7">
                            <div class="form-group">
                                <label v-if="lang == 'es'" for="pickup-date">Fecha de Retiro</label>
                                <label v-else for="pickup-date">Pick Up Date</label>

                                <input type="date" id="pickup-date" name="pickup_date" class="datetimepicker datepicker form-control" readonly/>
                            </div>
                        </div>

                        <div class="col-sm-5">
                            <div class="form-group">
                                <label v-if="lang == 'es'" for="pickup-time">Hora</label>
                                <label v-else for="pickup-time">Pick Up Hour</label>

                                <input type="time" id="pickup-time" name="pickup_hour" class="datetimepicker timepicker form-control" readonly/>
                            </div>
                        </div>
                    </div>

                    <div class="row mx-0">
                        <div class="col-sm-7">
                            <div class="form-group">
                                <label v-if="lang == 'es'" for="dropoff-date">Fecha de Devolución</label>
                                <label v-else for="dropoff-date">Drop Off Date</label>

                                <input type="date" id="dropoff-date" name="dropoff_date" class="datetimepicker datepicker form-control" readonly/>
                            </div>
                        </div>

                        <div class="col-sm-5">
                            <div class="form-group">
                                <label v-if="lang == 'es'" for="dropoff-time">Hora</label>
                                <label v-else for="dropoff-time">Drop Off Hour</label>

                                <input type="time" id="dropoff-time" name="dropoff_hour" class="datetimepicker timepicker form-control" readonly/>
                            </div>
                        </div>
                    </div>

                    <div class="row mx-0">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <select class="form-control" id="type-car" name="type_car" placeholder="TIPO DE AUTO">
                                        <slot></slot>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                        
                    <!-- End calendar options -->

                    <div class="row mx-0 my-2">
                        <div class="col-12 text-center">
                            <div class="input-btn w-100">
                                <input id="booking-buttom" class="btn btn-search mb-0 border-0 h-100" type="submit" :value="reserve_button_text"/>
                                <label for="booking-buttom" class="icon"><i class="fa fa-search" style="line-height: unset;"></i></label>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!--End Booking -->

</template>

<script>

    import Select2 from 'select2'
    import flatpickr from 'flatpickr'

    export default {
        props: ['settings'],
        data() {
            return {
                lang: "",
                show_tab: true,
                is_homepage: true,
                reserve_button_text: "Buscar"
            }
        },
        mounted() {

            var site_id         = this.settings.site_id
            var domain_url      = this.settings.domain_url
            var location_id     = this.settings.location_id
            var provider_id     = this.settings.provider_id
            var lang            = this.settings.lang
            var dropoff_id_user = this.settings.location_dropoff_id
            var pickup_date     = this.settings.pickup_date
            var dropoff_date    = this.settings.dropoff_date
            var pickup_hour     = this.settings.pickup_hour
            var dropoff_hour    = this.settings.dropoff_hour
            var tipo_auto       = this.settings.tipo_auto
            var is_homepage     = this.settings.homepage
            var params          = {lang}

            this.lang           = lang
            this.is_homepage    = is_homepage
            var blockUI_message = 'Cargando...'
            if (lang == 'en') {
                this.reserve_button_text = 'Find a Car'
                blockUI_message          = 'Please wait..'
            }

            var blockUI_conf = {
                message: '<div class="loader-container-block-ui"> <div class="loader-block-ui"></div> </div>',
                css: {
                    border: 'none',
                    padding: '5px',
                    backgroundColor: 'transparent',
                    //backgroundColor: '#000',
                    '-webkit-border-radius': '10px',
                    '-moz-border-radius': '10px',
                    //opacity: .5,
                    color: '#fff',
                    width: '60%',
                    left:'20%'
                }
            }

            $('#language').val(lang)
            $('#provider_id').val(provider_id)

            /** Selects pickup, drop off and type car */
            $('#pickup').select2({
                theme: 'bootstrap4',
                placeholder: this.settings.pickup_placeholder
            })

            /** Clicked when is empty */
            $('#select2-pickup-container').on('click', function() {
                //load after first render when clicked the select
                $('#pickup').select2('close');
                loadInit(true);
                getTypeCar(provider_id, location_id, false)
            })
            // On change event function
            $('#pickup').on('select2:select', function (event) {
                // Call new date from new location pickup selected
                location_id = $(this).val()
                getDropoff(location_id)
                getTypeCar(event.params.data.provider, event.params.data.id, false)
                // console.log(event.params.data.url);
                if (lang == 'en') {
                    var url = event.params.data.url.replace(/\/en/, '');
                }else{
                    var url = event.params.data.url;
                }
                $('#urlpost').val(url)
                $('#provider_id').val(event.params.data.provider)
            })

            //Interruptor functionality
            $('#return-somewhere-else').on('change', function(e) {
                var active = parseInt($(this).data('active'))
                var checked = $(this).prop('checked')

                if (!active) {
                    //(0) it was activated
                    $('.dropoff-selector').slideDown()
                    $(this).data('active', 1)

                    let dropoff_id = $('#dropoff').val()
                    // If has data not make request
                    if (dropoff_id == null) { getDropoff(location_id, false) }
                } else {
                    //(1) it was desactived
                    $('.dropoff-selector').slideUp()
                    $(this).data('active', 0)
                    $('#dropoff').val(location_id).trigger('change')
                }
            })

            $('#dropoff').select2({
                theme: 'bootstrap4',
                placeholder: this.settings.pickup_placeholder
            })

            /** Clicked when is empty */
            $('#select2-dropoff-container').on('click', function() {
                // Load data first time
                $('#dropoff').select2('close');
                getDropoff(location_id, true)
            });

            $('#type-car').select2({
                theme: 'bootstrap4',
                placeholder: this.settings.vehicle_placeholder,
                dropdownPosition: 'below'
            });

            /** Clicked when is empty */
            $('#select2-type-car-container').on('click', function() {
                // Load data first time
                $('#type-car').select2('close')
                getTypeCar(provider_id, location_id, true)
            })

            // Calendars flatpickr
            var minDate = (typeof pickup_date == 'undefined') ? moment().add(1, 'days').format('YYYY-MM-DD') : moment(pickup_date).format('YYYY-MM-DD')
            $('#pickup-date').flatpickr({
                disableMobile: true,
                dateFormat: "Y-m-d",
                defaultDate: minDate,
                minDate: moment().add(1, 'days').format('YYYY-MM-DD'),
                onChange: function(selectedDates, dateStr, instance) {
                    var nextDay = moment(dateStr).add(1, 'days').format('YYYY-MM-DD')
                    var drop_calendar = $('#dropoff-date').flatpickr({
                        disableMobile: true,
                        dateFormat: "Y-m-d",
                        defaultDate: nextDay,
                        minDate: nextDay
                    })

                    drop_calendar.open()
                }
            })

            var time = (typeof pickup_hour == 'undefined') ? moment("2099-12-30 11:00") : moment("2099-12-30 " + pickup_hour)
            $('#pickup-time').flatpickr({
                disableMobile: true,
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true,
                defaultDate: moment(time).format('HH:mm')
            })

            var minDateD = (typeof dropoff_date == 'undefined') ? moment(minDate).add('1', 'days').format('YYYY-MM-DD') : moment(dropoff_date).format('YYYY-MM-DD')
            $('#dropoff-date').flatpickr({
                disableMobile: true,
                dateFormat: "Y-m-d",
                defaultDate: minDateD,
                minDate: moment(minDate).add(1, 'days').format('YYYY-MM-DD')
            })

            var timeD = (typeof dropoff_hour == 'undefined') ? moment("2099-12-30 11:00") : moment("2099-12-30 " + dropoff_hour)
            $('#dropoff-time').flatpickr({
                disableMobile: true,
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true,
                defaultDate: moment(timeD).format('HH:mm')
            });

            // Avoid multiple click
            $(this).prop('disabled', false)

            //submit form
            $('#booking-form').on('submit', function (event) {
                event.preventDefault()
                event.stopPropagation()

                $('#booking-buttom').prop('disabled', true)

                let url      = $('#urlpost').val()
                let lang     = $('#language').val()
                // let redirect = (lang === 'en') ? url.substring(3, url.length) : url
                var params   = $(this).serialize()

                if (is_homepage) { $.blockUI(blockUI_conf) };
                

                ajaxRequest('/booking', params, 'POST', {"X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")}).then(response => {
                    // var args = {url: '/component/vehicles', async: false}
                    $.ajaxRequest('/component/vehicles').then(HTML => {
                        window.location = decodeURIComponent(url)
                    }).catch(err => {
                        Swal.fire('Ups!', 'Something went wrong! ' +  err.responseJSON.message, 'error')
                        console.log(err)
                    });
                }).finally(() => {
                    
                })
            })

            $('#edit-form').on('submit', function(event) {
                event.preventDefault()
                event.stopPropagation()

                var reservation_code  = $('#reservation_code').val()
                var reservation_email = $('#reservation_email').val()
                var url = '';
                if (lang == 'es') {
                    url = `/editar/reservacion/${reservation_code}/${reservation_email}`
                } else {
                    url = `/en/edit/reservation/${reservation_code}/${reservation_email}`
                }

                window.location = url;
            })

            /** In case we want to load everything from
                the begining you can use this */
            $(document).ready(function(event) {
                $.unblockUI();
                loadInit()
                $('#return-somewhere-else').prop('checked', false)
                $('#return-somewhere-else').data('active', 0)
                
                //getDropoff(location_id)
                if (tipo_auto != 0 && typeof tipo_auto != 'undefined') {
                    getTypeCar(provider_id, location_id, false)
                }
                
                if (typeof dropoff_id_user != 'undefined' && location_id != dropoff_id_user && is_homepage == false) {
                    $('#return-somewhere-else').click()
                }
                $('#booking-buttom').prop('disabled', false)
            })

            function loadInit (openDropdown = false) {
                //load data from booking first render
                $.ajax({
                    url: "/pickups",
                    dataType: 'json',
                    data: params,
                    async: true,
                }).done(function(response) {

                    if (response.error) {
                        Swal.fire('Ups!', 'Something went wrong! ' + response.message, 'error')
                        return true;
                    }       

                    var result = $.map(response.data, function (item, index) {
                        if (lang == 'en') {
                            var url = item.booking_url.replace(/\/en/, '');
                        }else{
                            var url = item.booking_url.replace(/\//, '/es/');
                        }
                        if (item.id == location_id) {$('#urlpost').val(url)}
                        return {id: item.id, text: item.name, provider:item.provider_id, code: item.code, url: url}
                    })

                    var provider_id = 0;
                    // Move to first element
                    $(result).each(function(index, item) {
                        if (item.id == location_id) {
                            result.unshift(item)
                            result.splice(index + 1 , 1)
                            provider_id = item.provider
                        }
                    })

                    $('#pickup').empty()
                    $('#pickup').select2({
                        theme: 'bootstrap4',
                        data: result,
                        selectOnClose: true
                    })

                    $('#pickup').val(location_id)
                    if (openDropdown) { $('#pickup').select2('open') }
                }).fail(function(xhr, status, error) {
                    Swal.fire('Ups!', 'Something went wrong!: ' + xhr.responseJSON.error, 'error');
                })
            }

            //Request to fill dropoff selector
            const getDropoff = (location_id, openDropdown = false) => {

                params.site_id     = this.settings.site_id
                params.domine      = this.settings.domain_url
                params.lang        = lang
                params.location_id = location_id

                ajaxRequest('/dropoffs', params).then(response => {

                    if (response.error) {
                        Swal.fire('Ups!', 'Something went wrong! ' + response.message, 'error')
                        return true
                    }

                    //processing data
                    response.data.filter((location) => {
                        if (location.id == location_id) {
                            var result = [];
                            $.each(location.dropoffs, function(index, dropoff) {
                                result.push({id: dropoff.id, text: dropoff.name});
                            });

                            $('#dropoff').empty()
                            $('#dropoff').select2({
                                theme: 'bootstrap4',
                                data: result
                            });

                            $('#dropoff').parent().find(".select2-container").css('width', '100')

                            var active = parseInt($('#return-somewhere-else').data('active'));
                            if (!active) {
                                $('#dropoff').val(location_id).trigger('change')
                            } else {
                                // Is active
                                if (typeof dropoff_id_user != 'undefined') { $('#dropoff').val(dropoff_id_user).trigger('change') }
                                if (openDropdown) { $('#dropoff').select2('open') }
                            }
                        }
                    })
                })
            }

            //get type cars by provider
            const getTypeCar = (provider_id, location_id, openDropdown = true) => {

                let lang        = params.lang
                let type        = 'type'
                let code_search = provider_id

                ajaxRequest('/location/url', {lang, type, location_id, code_search}).then(response => {
                    
                    if (response.error) {
                        Swal.fire('Error', response.message, 'error');
                        return true;
                    }
                    
                    var result_type = $.map(response.data, function (item) {
                        return {id: item.id, text: item.description}
                    })
                    
                    result_type.unshift({id: 0, text: this.settings.vehicle_placeholder})

                    $('#type-car').empty()
                    $('#type-car').select2({
                        theme: 'bootstrap4',
                        data: result_type,
                        dropdownPosition: 'below'
                    })

                    if (tipo_auto != 0 && typeof tipo_auto != 'undefined') {
                        $('#type-car').val(tipo_auto).trigger('change');
                    }

                    if (openDropdown) { $('#type-car').select2('open') }
                })
            }

            //call ajax method
            const ajaxRequest = (url, params = [], method = "GET", headers = [], async = true) => {
                return new Promise((resolve, reject) => {
                    $.ajax({
                        url : url,
                        method : method,
                        headers: headers,
                        dataType: 'json',
                        data : params,
                        async : true
                    }).done(resolve).fail(function(error) {
                        Swal.fire(
                            error.status + ' - Error',
                            error.responseText,
                            'error'
                        );
                        console.log(error)
                    });
                })
            }
        }
    }

    $('#pickup').parent().find(".select2-container").css('width', '100')
    $('#dropoff').parent().find(".select2-container").css('width', '100')
</script>
